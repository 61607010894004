<template>
  <section id="eye-values-template">
    <b-row >
      <b-col>
        <h2 v-if="title">
          {{ title }}
        </h2>
        <b-row >
          <b-col cols="12" >
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div 
                    v-for="(input, index) in inputs" 
                    :key="index"
                    class="custom-input eye-box-container"
                  >
                    <div class="text-area type-2 with-br">
                      <span>
                        {{ input }}
                      </span>
                      <div>
                        <input
                          type="text"
                          class="form-control text-center"
                          readonly
                          disabled
                        >
                      </div>
                    </div>
                  </div>            
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area">
                    <EyeFill /> E
                  </div>
                  <div 
                    v-for="(input, index) in inputs" 
                    :key="index"
                    class="custom-input eye-box-container"
                  >
                    <div class="text-area type-2 with-br">
                      <div>
                        <input
                          type="text"
                          class="form-control text-center"
                          readonly
                          disabled
                        >
                      </div>
                    </div>
                  </div>   
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  components: {
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
  },
  props: {
    title: String,
    inputs: Array
  }
}
</script>

<style lang="scss" scoped>
#eye-values-template {
  h2 {
    font-weight: 700;
    font-size: 16px;
    color: var(--dark-blue);
  }
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      height: 48px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
    }
  label {
    margin-top: 16px;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    color: var(--dark-blue);
  }

  .eyeValueChange {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 !important;
    position: relative;
    margin-left: -30px !important;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;

      button {
        height: 20px;
        width: 30px;
        color: var(--greys-60);
        background-color: #fff;
        position: relative !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1.5px solid var(--blue-100) !important;

        p {
          font-weight: 700;
        }

      }
    }
  }

  .eye-box-container {
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: var(--blue-500)
  }
}
</style>